/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

/* @font-face {
    font-family: "HelveticaNeue";
    src: url("/assets/fonts/Helvetica.ttf")
  } */
  body{
    font-family: 'Poppins', sans-serif;
  }
  .row{
    margin: 0px;
}